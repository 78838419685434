@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


@layer base{
    body{
        background-color: #000;
        color: white ;
        margin: 0;
        font-family: "Inter", sans-serif;
       
    }
    .rp{
        @apply sm:px-20 px-10 break-words  ;
    }

    .pa{
        @apply md:ml-28 absolute 
    }

    .page-button{
       @apply font-semibold border-slate-100 border-2 sm:w-32 sm:h-14 w-28 h-12 sm:text-lg text-sm rounded-md hover:border-red-700 hover:bg-red-700 hover:scale-105 duration-200 ease-linear transform
    }
      /* request page  */
      /* bg  */
    .requestbg{
      width: full;
      height: screen;
      --color: rgba(114, 114, 114, 0.3);
      background-color: #191a1a;
      background-image: linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
      linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent);
      background-size: 55px 55px;
    }

    .cyberpunk-checkbox {
      appearance: none;
      width: 15px;
      height: 15px;
      border: 2px solid #ea1111;
      border-radius: 5px;
      background-color: transparent;
      display: inline-block;
      position: relative;
      margin-right: 8px;
      cursor: pointer;
    }
    
    .cyberpunk-checkbox:before {
      content: "";
      background-color: #ea1111;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 8px;
      height: 8px;
      border-radius: 2px;
      transition: all 0.3s ease-in-out;
    }
    
    .cyberpunk-checkbox:checked:before {
      transform: translate(-50%, -50%) scale(1);
    }
    
    .cyberpunk-checkbox-label {
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
    }

    .cat-label {
      font-size: 16px;
      border: 2px solid #fff;
      color: #fff;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
    }


    .cat{
      appearance: none;
      width: 15px;
      height: 15px;
      border: 2px solid #fff;
      border-radius: 5px;
      background-color: transparent;
      display: inline-block;
      position: relative;
      margin-right: 8px;
      cursor: pointer;
    }

    .cat:before{
      content: "";
      background-color: #ea1111;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 8px;
      height: 8px;
      border-radius: 2px;
      transition: all 0.3s ease-in-out;
    }


    .cat:checked:before {
      transform: translate(-50%, -50%) scale(1);
    }
    .cat:checked {
      border: 2px solid #ea1111;

    }


      

    /* Hide scrollbar for Firefox */
* {
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

.flex {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.flex::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

    
}


